import styled, { css } from "styled-components";

/* 
---------------------------------------
Base Card Styles for all News Cards
---------------------------------------
*/
export const BaseCardWrapper = styled.div`
  display: flex;
  align-content: center;
  flex-wrap: wrap;

  .card {
    display: flex;
    flex-direction: column;

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      flex-direction: row;
      padding: 20px 0;
    }
  }

  .date-wrapper {
    position: relative;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.15em;
    text-align: left;
    color: #a3a3a3;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .divider {
    margin-right: 10px;
    margin-left: 10px;
  }

  .card-article-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0.15em;
    text-align: left;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .card-article-copy {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: left;
  }

  .additional-content {
    font-size: 0.75rem;
    color: #a3a3a3;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: 0.15em;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  a {
    display: flex;
    color: #30353f;
    height: 100%;
    text-decoration: none;

    &:hover {
      color: #4a5160;
    }
  }
`;
/*
---------------------------------------
Wrapper for Cards displayed on in Archive section of News Page
---------------------------------------
*/
export const NewsArchiveCardWrapper = styled(BaseCardWrapper)`
  padding-top: 36px;

  .card {
    transition: 0.3s;
    vertical-align: top;
    padding: 0 20px;

    .img-wrapper {
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
        max-width: 100%;
      }
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        max-width: 50%;
      }
    }

    .image {
      height: auto;
      max-width: 100%;

      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
        box-shadow: 4px 4px #111111;
      }

      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        flex-direction: row;
        height: auto;
        width: 100%;

        box-shadow: none;
      }
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      flex-direction: row;
      padding: 0px 0;
    }
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    width: 100%;

    .seperator,
    .additional-content {
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        display: none;
      }
    }

    .divider,
    .category {
      display: none;

      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        position: relative;
        display: flex;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.15em;
        text-align: left;
        color: #a3a3a3;
        margin-bottom: 8px;
        text-transform: uppercase;
      }
    }

    .card-article-copy {
      margin-bottom: 16px;
    }

    .author {
      font-size: 0.75rem;
      color: #a3a3a3;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 1.56;
      letter-spacing: 0.15em;
    }

    .seperator {
      position: relative;
      padding: 0;
      margin: 12px 0 8px;
      border-bottom: 1px solid #8b8682;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
      width: 100%;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      padding-left: 50px;
      max-width: 50%;
    }
  }
`;

/*
---------------------------------------
Wrapper for Cards displayed on Latest News section of home
---------------------------------------
*/
export const HomeNewsCarouselCardWrapper = styled(BaseCardWrapper)`
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  width: 100%;

  .card {
    height: 100%;
    padding: 0;
    transition: box-shadow 1s ease-in-out;
    width: 100%;

    .img-wrapper {
      overflow: hidden;

      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
        flex-shrink: 0;
        width: 100%;
      }

      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        max-width: 187px;
      }
    }

    .image {
      height: auto;
      transition: 0.5s all ease-in-out;

      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
        width: 100%;
      }

      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        flex-direction: row;
        height: 100%;
        max-width: 187px;
      }
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
      border: 3px solid black;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      border: none;
      box-shadow: 5px 5px #111111;
      flex-direction: row;
      padding: 0px 0;

      &:hover {
        box-shadow: 10px 10px #111111;

        .image {
          transform: scale(1.1);
        }
      }
    }
  }

  .info-wrapper {
    width: 100%;

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
      background: #ffffff;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 32px 30px;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      padding: 32px 30px;
    }

    .additional-content {
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
        font-size: 0.75rem;
        color: #a3a3a3;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 16px;
        letter-spacing: 0.15em;
      }

      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        display: none;
      }
    }

    .seperator {
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
        border-bottom: 1px solid #8b8682;
        margin: auto 0 0.75rem;
        padding: 0.3rem 0 0;
        position: relative;
      }

      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        display: none;
      }
    }

    .category,
    .divider {
      display: none;
    }
  }

  .card-article-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0.15em;
    text-align: left;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .card-article-copy {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    padding: 20px;
    display: flex;
    justify-content: center;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 20px;
    display: flex;
    justify-content: center;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 0px;
  }
`;

/*
---------------------------------------
Wrapper for Cards displayed on "Up Next" Footer of individual Articles
---------------------------------------
*/
export const RelatedCardWrapper = styled(BaseCardWrapper)`
  padding-top: 28px;

  .card {
    transition: 0.3s;
    vertical-align: top;
    padding: 0 20px;

    .image {
      height: auto;
      max-width: 100%;
      box-shadow: 5px 10px #111111;

      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
        margin-bottom: 30px;
      }

      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        flex-direction: row;
        height: auto;
        width: 100%;
        min-width: 400px;
        max-width: 605px;
      }
    }
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      flex-direction: row;
      padding: 0px 0;
    }
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 416px;

    .card-article-copy {
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
        margin-bottom: 20px;
      }
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        margin-bottom: 8px;
      }
    }

    .author,
    .category,
    .divider {
      display: none;
    }

    .additional-content {
      font-size: 0.75rem;
      color: #a3a3a3;
      font-weight: 400;
      margin-top: 10px;
      text-transform: uppercase;
      line-height: 16px;
      letter-spacing: 0.15em;
    }

    .additional-content {
      font-weight: 700;
    }

    .seperator {
      position: relative;
      padding: 0;
      margin: 0.4rem 0 0.2rem;
      border-bottom: 1px solid #8b8682;
    }

    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      padding-left: 50px;
    }
  }
`;
