import { bcpLocaleFromString } from "../helpers/rioti18n";

export const localeDateString = (
  date: string | number | Date,
  locale: string
) => {
  const bcpLocale = bcpLocaleFromString(locale);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };

  let dateString = new Date(date).toLocaleDateString(
    bcpLocale,
    options
  );

  dateString = dateString.replace(/,/g, ',');

  return dateString;
};
