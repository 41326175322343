import RiotLocale from "../helpers/rioti18n";
import riotLocaleToBcp47 from './riot-locale-to-bcp47';

/**
 * Returns a string with the first character of source capitalized, if that character is alphabetic.
 *
 * @method capitalize
 * @param {string} value The original string.
 * @returns {string}
 */
export const capitalize = (value: string): string => {
  return value
    ? value.substr(0, 1).toUpperCase() + value.toLowerCase().substr(1)
    : value;
};

/**
 * Remove special characters then replace it to dash
 *
 * @method removeSpecialChars
 * @param {string} value The original string.
 * @param {string} replaceWith The string that will replace instances of replace string.
 * @returns {string}
 */
export const removeSpecialChars = (value: string, replaceWith = ''): string => {
  return value
    .replace(/[\t\r\n]|\s\s/g, '')
    .replace(/\W+(?!$)/g, replaceWith)
    .replace(/\W$/, replaceWith);
};

// Removes whitespace from the end of the specified string.
const trimRight = (value: string) => {
  const size = value.length;

  for (let i = size; i > 0; i -= 1) {
    if (value.charCodeAt(i - 1) > 32) {
      return value.substring(0, i);
    }
  }

  return '';
};

// Returns a string truncated to a specified length with optional suffix.
export const truncate = (
  value: string,
  length: number,
  suffix = '...',
): string => {
  if (value == null) {
    return '';
  }

  length -= suffix.length;

  let trunc = value;

  if (trunc.length > length) {
    trunc = trunc.substr(0, length);

    if (/[^\s]/.test(value.charAt(length))) {
      trunc = trimRight(trunc.replace(/\w+$|\s+$/, ''));
    }
    trunc += suffix;
  }

  return trunc;
};

export const base64UrlEncode = (bytes: string | Uint8Array): string => {
  if (typeof bytes === 'string') {
    return btoa(bytes);
  }
  return btoa(String.fromCharCode.apply(null, bytes as any))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

export const randomBytes = (count: number): Uint8Array => {
  const bytes = new Uint8Array(count);
  const cryptoObj = window.crypto || (window as any).msCrypto;
  return cryptoObj.getRandomValues(bytes);
};

export const randomString = (count: number): string =>
  randomBytes(count).toString();
