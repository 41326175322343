import { Link } from "gatsby-plugin-react-i18next";
import React, { useContext } from "react";
import { localeDateString } from "../../utils/date-utils";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import Locale from "../../data/contexts/riot-locale";
import { truncate } from "../../utils/string-utils";
import * as S from "./styles";

//custom classes for each new card use case
export const NEWS_CARD_TYPE_CLASSES = {
  base: "base",
  newsArchive: "news-archive",
  newsCarousel: "news-carousel",
  newsRelated: "news-related",
};

const getNewsCard = (newsCardType = NEWS_CARD_TYPE_CLASSES.base) =>
  ({
    [NEWS_CARD_TYPE_CLASSES.base]: S.BaseCardWrapper,
    [NEWS_CARD_TYPE_CLASSES.newsArchive]: S.NewsArchiveCardWrapper,
    [NEWS_CARD_TYPE_CLASSES.newsCarousel]: S.HomeNewsCarouselCardWrapper,
    [NEWS_CARD_TYPE_CLASSES.newsRelated]: S.RelatedCardWrapper,
  }[newsCardType]);

type Props = {
  uid: string;
  date: string;
  url: {
    url: string;
  };
  authors?: Array<{
    title: string;
  }>;
  title: string;
  description: string;
  category?: Array<{
    title: string;
    machine_name: string;
  }>;
  banner: {
    featured_banner_url: {
      url: string;
    };
    small_carousel_url: {
      url: string;
    };
  };
  cardType: "large" | "small";
  external_link: string;
  index: number;
  onClick?: () => void;
  newsCardType: string;
};

const NewsCard: React.FC<Props> = ({
  uid,
  date,
  authors,
  url,
  title,
  description,
  category,
  banner,
  cardType,
  external_link,
  index,
  onClick,
  newsCardType,
}) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const localeDate = localeDateString(date, language);
  const imageUrl = cardType === "large" ? banner?.featured_banner_url?.url : banner?.small_carousel_url?.url;
  const CustomNewsCard = getNewsCard(newsCardType);

  const card = (
    <CustomNewsCard>
      <div className="card">
        <div className="img-wrapper">
          <img className="image" src={imageUrl} />
        </div>
        <div className="info-wrapper">
          <div className="date-wrapper">
            {category?.length && <span className="category">{category[0].title}</span>}{" "}
            <span className="divider">|</span>
            {date && <span>{localeDate}</span>}
          </div>
          <div className="card-article-title">{truncate(title, 60)}</div>
          <div className="card-article-copy">{truncate(description, 120)}</div>

          {authors?.length && <span className="author">{authors[0].title}</span>}
          <span className="seperator" />
          <span className="additional-content">{t("more")}</span>
        </div>
      </div>
    </CustomNewsCard>
  );

  return (
    <S.CardContainer>
      {external_link ? (
        <a href={external_link} target="_blank">
          {card}
        </a>
      ) : (
        <Link to={url?.url} onClick={onClick}>
          {card}
        </Link>
      )}
    </S.CardContainer>
  );
};

export default NewsCard;
